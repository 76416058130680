import { checkConsentForPurpose } from "./integration/didomi";
import { getArticleIgnoreList } from "./helpers/getTeaserIgnoreList";
async function autoTeaser() {
  const hasConsent = await new Promise(resolve => {
    setTimeout(() => resolve(false), 1000);
    checkConsentForPurpose("marketing", resolve);
  });
  const autoteasers = window.document.querySelectorAll(".teaser[data-autoteaser=true]");
  if (!autoteasers.length) return;
  const content = document.getElementById("content");
  const teasers = content.querySelectorAll(".teaser");
  for (const autoteaser of autoteasers) {
    const index = autoteaser.dataset.position;
    const contentId = autoteaser.dataset.sectionid;
    const timeWindow = autoteaser.dataset.timewindow;
    const articleIgnoreList = getArticleIgnoreList(teasers, autoteaser);
    const url = new URL("/poof", window.location.origin);
    const response = await fetch(url.href, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ignoreList: articleIgnoreList,
        pathname: window.location.pathname || "",
        timeWindow,
        contentId,
        hasConsent,
        index
      }),
      cache: "no-store"
    });
    if (response.status !== 200) {
      autoteaser.classList.remove("visibility-hidden");
      return;
    }
    const personalizedContent = await response.text();
    const template = document.createElement("template");
    template.innerHTML = personalizedContent;
    autoteaser.replaceChildren(...template.content.querySelector(".teaser").children);
    autoteaser.classList.remove("visibility-hidden");
  }
}
autoTeaser();